import { Container, Spinner } from "@chakra-ui/react";
import React from "react";

const Loader = () => {
  
  return (
    <Container
      maxW={"3xl"}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
      }}
    >
      <Spinner size="xl" />
    </Container>
  );
};

export default Loader;
