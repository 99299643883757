import React from "react";
import { Box, Flex, FormControl, Input, Text, Heading, Textarea } from "@chakra-ui/react";
import CustomRadio from "./Radio";

export const RenderQuestion = ({
  question,
  index,
  onChange,
  onChangeOption,
  onChangeNestedQuestion,
}) => {
  const handleInputChange = (e) => {
    const value = e.target.value;
    onChange(value);
  };
  const handleOptionChange = (optionIndex, newOptions) => {
    onChangeOption(optionIndex, newOptions);
  };
  const nestedQuestionOption = ["a", "b", "c", "d", "e"];

  return (
    <FormControl mb={2} mt={2}>
      {question.heading && (
        <Box mt={4} mb={2}>
          <Heading as={"h"} size={!!question.question ? "sm" : "md"} color={"gray.600"}>
            {question.heading}
          </Heading>
        </Box>
      )}
      <Flex>
        {(!!question.heading || !!question.question) && (!!question.question && !question.value) && (
          <Flex justifyContent={"center"} alignItems={"center"} ml={-8} mr={2}>
            <Box width={"max-content"}>Q{question.sequence}</Box>
          </Flex>
        )}
        {(!!question.question || !!question.value) && (
           <Textarea
           defaultValue={question.question || question.value}
           name={question.name}
           onChange={handleInputChange}
           resize="vertical"
           minH={((question?.question)?.trim().length> 80 || (question?.value)?.trim().length> 80) ? "60px" : "30px"}
         />)}
      </Flex>
      {((!question.isNested && question.question_type === "quick_replies") ||
        (!question.isNested && question.question_type === "multiselect")) && (
        <CustomRadio
          isDisabled={question?.isDisabled}
          options={question.options} // Display options for quick_replies and multiselect
          type={question.type}
          onChange={handleOptionChange}
        />
      )}
      {question.nestedQuestion &&
        question.question_type === "quick_replies" &&
        question.nestedQuestion?.map((radio, index) => (
          <>
            <Flex key={radio}>
              <Text
                paddingTop={2}
                paddingRight={2}
              >{`(${nestedQuestionOption[index]}) `}</Text>
              <Input
                defaultValue={radio}
                key={radio + index}
                autoFocus
                onChange={(e) => onChangeNestedQuestion(index, e.target.value)}
              />
            </Flex>
            <CustomRadio
              options={question.options} // Display options for quick_replies and multiselect
              type={question.type}
              onChange={handleOptionChange}
            />
          </>
        ))}
    </FormControl>
  );
};
