import * as React from "react";
import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./utils/theme";
import { BrowserRouter, Routes, Route, Navigate, useLocation, useSearchParams } from "react-router-dom";
import Routing from "./Routing";
import { RecoilRoot } from "recoil";
import UpdateExisting from "./components/UpdateExisting";
import SignIn from "./components/SignIn";
import ProtectedRoute from "./components/ProtectedRoute";
import { useEffect } from "react";
import { auth } from "./firebase";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <RecoilRoot>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </RecoilRoot>
    </ChakraProvider>
  );
}

function AppRoutes() {
  return (
    <Routes>
      {/* Public route for sign in */}
      <Route 
        path="/signin" 
        element={
          <PublicRoute>
            <SignIn />
          </PublicRoute>
        } 
      />
      
      {/* Protected routes - Use path="/*" for nested routes */}
      <Route 
        path="/*" 
        element={
          <ProtectedRoute>
            <Routing />
          </ProtectedRoute>
        } 
      />
      
      {/* Catch all route - redirect to signin */}
      <Route 
        path="*" 
        element={<Navigate to="/signin" replace />} 
      />
    </Routes>
  );
}

// Wrapper for public routes (signin)
function PublicRoute({ children }) {
  const location = useLocation();
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // If user is already authenticated, redirect to home
        window.location.href = `/?clientId=${user.uid}`;
      }
    });

    return () => unsubscribe();
  }, []);

  return children;
}

// Create a wrapper component to extract URL parameters
function UpdateExistingWrapper() {
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get("clientId");
  const productId = searchParams.get("productId");
  
  return <UpdateExisting clientId={clientId} productId={productId} />;
}

export default App;
