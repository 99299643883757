import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Flex,
  FormLabel,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
  useClipboard,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { collection, doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { getUserSurveysDetails, updateSurveyDetails } from "../utils/Helper";
import CustomModal from "./CustomModal";
import CustomSelect from "./Select";
import { useSetRecoilState } from 'recoil';
import { ProductState } from "../state/atom/ProductState";

const CustomCard = ({
  survey,
  clientId,
  HandleDelete,
  deleteLoading,
  setRefetch,
  surveyName,
  setData,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onCopy, setValue, hasCopied } = useClipboard("");
  const [isEdit, setIsEdit] = useState(false);
  const [isLiveLoader, setIsLiveLoader] = useState(false);
  const [surveyDetails, setSurveyDetails] = useState({
    name: survey?.name,
    description: survey?.description,
    age: survey?.age,
    welcomeMsg: survey?.welcomeMsg,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const setProduct = useSetRecoilState(ProductState);
  let url =`https://${(window.location.protocol ==="http:" || window.location.hostname ==="staging.concept.noeticdigital.app") ? "staging." : ""}survey.noeticdigital.app/?clientId=${clientId}&surveyId=${survey?.id}&survey=${surveyName}&lang=${survey?.language}`;

  useEffect(() => {
    setValue(url);
  }, [survey?.id]);
  const HandleEdit = () => {
    if (isEdit) {
      try {
        setLoading(true);
        return updateSurveyDetails(
          surveyName,
          survey?.id,
          surveyDetails?.name,
          surveyDetails?.description,
          surveyDetails?.age,
          surveyDetails?.welcomeMsg
        ).then((res) => {
          setRefetch(Math.random(9) * 50);
          setTimeout(() => {
            setLoading(false);
            setIsEdit((prev) => !prev);
          }, 1000);
        });
      } catch (error) {
        setLoading(false);
      }
    }
    setIsEdit(!isEdit);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleLiveCall = async () => {
    try {
      if (survey?.ps_survey_id) {
        setIsLiveLoader(true);
        const { data } = await axios({
          method: "put",
          url: `${process.env.REACT_APP_PS_BASE_URL}/buyers/v2/surveys/${survey?.ps_survey_id}/status`,
          data: {
            ps_survey_status: survey?.isLive ? 33 : 22,
          },
          headers: {
            "access-token": process.env.REACT_APP_PS_ACCESS_TOKEN,
          },
        });
        const surveysCollectionRef = collection(db, "surveys");
        const surveyRef = doc(surveysCollectionRef, survey?.id);
        if (data) {
          await updateDoc(surveyRef, { isLive: !survey?.isLive });
          getUserSurveysDetails(clientId, surveyName).then((res) => {
            setData(res);
            setIsLiveLoader(false);
            onClose();
          });
        }
      }
    } catch (error) {
      setIsLiveLoader(false);
      toast({
        title:
          error?.response?.data?.ps_api_response_message ||
          "Something went wrong",
        position: "top-right",
        isClosable: true,
        variant: "left-accent",
        status: "error",
      });
    }
  };

  const handleEditClick = () => {
    setProduct(survey.data || survey);
    navigate(`/update-existing?clientId=${clientId}&surveyName=${surveyName}&productId=${survey.id}`);
  };

  return (
    <>
      <CustomModal
        text={`Do you want to ${survey?.isLive ? "pause" : "live"} this survey`}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleLiveCall}
        loading={isLiveLoader}
        confirmBtnText={"Yes"}
        cancelBtnText={"No"}
      />
      <Card minW={400} maxW={400}>
        <CardBody minH={172} maxH={395} overflowY={"scroll"}>
          <Stack spacing="3">
            {survey?.image_url && (
              <Image
                src={survey?.image_url}
                maxH={200}
                maxW={200}
                alignSelf={"center"}
              />
            )}
            <Flex justifyContent={"space-between"} alignItems="center">
              {isEdit ? (
                <div>
                  <FormLabel>Product Name</FormLabel>
                  <Input
                    maxW={245}
                    value={surveyDetails?.name}
                    onChange={(e) =>
                      setSurveyDetails((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                </div>
              ) : (
                <Heading size="md" maxW={250}>
                  {survey?.name}
                </Heading>
              )}
              <Button
                onClick={HandleEdit}
                isLoading={loading}
                isDisabled={loading}
                marginX={isEdit && 2}
                marginTop={isEdit && 7}
              >
                {isEdit ? "Save" : "Edit"}
              </Button>
              {isEdit && (
                <Button
                  marginTop={isEdit && 7}
                  onClick={() => setIsEdit(false)}
                  isDisabled={loading}
                >
                  X
                </Button>
              )}
            </Flex>
            {!isEdit && (
              <Flex>
                <Heading size="sm" paddingTop={2} paddingRight={2}>
                  Language:
                </Heading>
                <Text paddingTop={1.5}>
                  {survey?.language === "jap" ? "Japanese" : "English"}
                </Text>
              </Flex>
            )}
            {isEdit ? (
              <>
                <FormLabel marginBottom={-1}>Welcome Message</FormLabel>
                <Textarea
                  value={surveyDetails?.welcomeMsg}
                  onChange={(e) =>
                    setSurveyDetails((prev) => ({
                      ...prev,
                      welcomeMsg: e.target.value,
                    }))
                  }
                />
              </>
            ) : (
              <>
                <Heading size="sm" marginRight={1} marginBottom={-2}>
                  Welcome
                </Heading>
                <Text>{survey?.welcomeMsg || " "}</Text>
              </>
            )}
            {isEdit ? (
              <>
                <FormLabel marginBottom={-1}>Product Description</FormLabel>
                <Textarea
                  value={surveyDetails?.description}
                  onChange={(e) =>
                    setSurveyDetails((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // Replace Enter key with newline character
                      setSurveyDetails((prev) => ({
                        ...prev,
                        description: e.target.value + "\n",
                      }));
                      e.preventDefault();
                    }
                  }}
                />
              </>
            ) : (
              <>
                <Heading size="sm" marginRight={1} marginBottom={-2}>
                  Description
                </Heading>
                <Text style={{ whiteSpace: "pre-line" }}>
                  {survey?.description || " "}
                </Text>
              </>
            )}
            {isEdit && <FormLabel marginBottom={-1}>Audience</FormLabel>}
            <Flex alignItems="center">
              {isEdit ? (
                <>
                  <CustomSelect
                    placeholder="Select Audience"
                    value={surveyDetails?.age}
                    name="age"
                    onChange={(e) =>
                      setSurveyDetails((prev) => ({
                        ...prev,
                        age: e.target.value,
                      }))
                    }
                  />
                </>
              ) : (
                <>
                  <Heading size="sm" marginRight={1}>
                    Audience:
                  </Heading>
                  <Text>
                    {`${survey?.age} (7 Men + 7 Women)` || "Not Available"}
                  </Text>
                </>
              )}
            </Flex>
            {survey?.sheetId && survey?.tabId && surveyName && (
              <Button
                variant="solid"
                colorScheme="teal"
                onClick={() =>
                  openInNewTab(
                    `https://docs.google.com/spreadsheets/d/${survey.sheetId}/edit#gid=${survey.tabId}`
                  )
                }
              >
                View Sheet
              </Button>
            )}
          </Stack>
        </CardBody>
        <Divider />
        <CardFooter flexDirection={"column"}>
          <ButtonGroup spacing="2" width={"100%"} justifyContent={"center"}>
            <Button
              variant="solid"
              colorScheme="teal"
              onClick={handleEditClick}
            >
              Edit Survey
            </Button>
            <Button variant="solid" color={"teal"} onClick={onOpen}>
              {survey?.isLive ? "Pause Survey" : "Go Live"}
            </Button>
          </ButtonGroup>
          <ButtonGroup
            mt={2}
            spacing="2"
            width={"100%"}
            justifyContent={"center"}
          >
            <Button
              onClick={() => {
                setValue(url);
                onCopy(url);
              }}
            >
              {hasCopied ? "Link Copied!" : "Copy Link"}
            </Button>
            <Button
              variant="ghost"
              color="red"
              isLoading={deleteLoading === survey?.id}
              // isDisabled={!!deleteLoading}
              loadingText={"Delete"}
              onClick={() => HandleDelete(clientId, survey?.id)}
            >
              Delete Survey
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    </>
  );
};

export default CustomCard;
