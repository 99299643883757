import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { auth } from "../firebase";
import Loader from "./Loader";

const ProtectedRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
      setUserId(user?.uid);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    // Redirect to sign in page, but save the location they tried to access
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  // If we're on the root path and there's no clientId in the URL, add it
  if (location.pathname === "/" && !location.search.includes("clientId") && userId) {
    return <Navigate to={`/?clientId=${userId}`} replace />;
  }

  return children;
};

export default ProtectedRoute; 