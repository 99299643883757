import {
  Box,
  Button,
  Checkbox,
  createIcon,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItemOption,
  MenuList,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { collection, doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { useNavigate, useSearchParams } from "react-router-dom";
import { db } from "../firebase";
import { headings, surveyNameMapping, surveys } from "../utils/constant";
import { createSurveys, welcomeMsgEng, welcomeMsgJap } from "../utils/Helper";
import CustomDropdown from "./CustomDropdown";
import CustomModal from "./CustomModal";
import { genderData, languages, surveyData } from "./helper";
import "./style.css";

const CreateNew = ({ clientId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams] = useSearchParams();
  const surveyName = searchParams.get("surveyName");

  const navigate = useNavigate();
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [isLiveLoader, setIsLiveLoader] = useState(false);
  const [response, setResponse] = useState({});
  const [language, setLanguage] = useState({
    name: "English",
    code: "eng",
    survey_Localization: "en_US",
  });

  const [formValues, setFormValues] = useState([
    {
      name: "",
      description: "",
      welcomeMsg: language?.name === "English" ? welcomeMsgEng?.[surveyName] || "" : welcomeMsgJap?.[surveyName] || "",
      image: "",
      age: "",
      email: "",
      surveyCategory: "",
      completes_required: 40,
      no_of_respondents: 0,
      expected_ir: 10,
      expected_loi: 1,
      field_time: 3,
      qualifications: [
        {
          qualification_code: 211,
          condition_codes: [],
        },
        {
          qualification_code: 212,
          range_sets: [{ units: 311 }],
        },
      ],
    },
  ]);

  const handleSelectGender = (i, value) => {
    let newFormValues = [...formValues];

    newFormValues[i].qualifications = newFormValues[i].qualifications.map(
      (qualification) => {
        if (qualification.qualification_code === 211) {
          if (value === "Select All") {
            let allConditionCodes =
              qualification.condition_codes.length === genderData.length
                ? []
                : genderData.map((option) => option.value);
            return { ...qualification, condition_codes: allConditionCodes };
          } else {
            const updatedConditionCodes =
              qualification.condition_codes.includes(value)
                ? qualification.condition_codes.filter((code) => code !== value)
                : [...qualification.condition_codes, value];

            return { ...qualification, condition_codes: updatedConditionCodes };
          }
        }
        return qualification;
      }
    );

    setFormValues(newFormValues);
  };

  const handleSelectRange = (i, value, name) => {
    let newFormValues = [...formValues];

    newFormValues[i].qualifications = newFormValues[i].qualifications.map(
      (qualification) => {
        if (qualification.qualification_code === 212) {
          const updatedRangeSets = qualification.range_sets.map((range) => ({
            ...range,
            [name]: value,
          }));

          return { ...qualification, range_sets: updatedRangeSets };
        }
        return qualification;
      }
    );

    setFormValues(newFormValues);
  };

  let handleChange = async (i, e) => {
    let newFormValues = [...formValues];
    if (e.target.name === "image" && e?.target?.files?.length > 0) {
      // Handle the image input
      newFormValues[i][e.target.name] = e.target.files[0];
    } else {
      // Handle other input fields
      newFormValues[i][e.target.name] = e.target.value;
    }
    setFormValues(newFormValues);
  };

  let handleEnter = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value + "\n";
    e.preventDefault();
    setFormValues(newFormValues);
  };


  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    let errorMessages = [];

    formValues.forEach((val, index) => {
      if (val.name.trim().length < 2) {
        // errorMessages.push(`Product name is too small for entry ${index + 1}.`);
        errorMessages.push(`Product name is too small`);
      }
      if (!val.surveyCategory) {
        errorMessages.push(
          // `Survey category is required for entry ${index + 1}.`
          `Survey category is required.`
        );
      }
      if (val.qualifications) {
        val.qualifications.forEach((qual) => {
          if (qual.qualification_code === 211 && !qual.condition_codes.length) {
            errorMessages.push(
              // `Gender is required ${qualIndex + 1} in entry ${index + 1}.`
              `Gender is required.`
            );
          }
          if (qual.qualification_code === 212) {
            if (qual.range_sets && qual.range_sets[0]) {
              const rangeSet = qual.range_sets[0];
              if (rangeSet.from === undefined || rangeSet.from < 18) {
                errorMessages.push(
                  // `From age value must be at least 5 for qualification ${
                  //   qualIndex + 1
                  // } in entry ${index + 1}.`
                  `From age must be at least 18`
                );
              }
              if (rangeSet.to === undefined || rangeSet.to > 100) {
                errorMessages.push(
                  // `"To" value must be at most 100 for qualification ${
                  //   qualIndex + 1
                  // } in entry ${index + 1}.`
                  `To age must be at most 100`
                );
              }
            }
          }
        });
      }
    });

    if (!!errorMessages.length) {
      errorMessages.forEach((message) => {
        toast({
          title: message,
          position: "top-right",
          isClosable: true,
          variant: "left-accent",
          status: "error",
        });
      });
      return;
    }

    setIsSaving(true);
    const { surveyDataResponse } = await createSurveys(
      clientId,
      formValues,
      language?.name,
      surveyNameMapping?.[surveyName],
      language?.survey_Localization
    );
    setIsSaving(false);
    if (surveyDataResponse?.ps_id) {
      onOpen();
      setResponse(surveyDataResponse);
    } else {
      toast({
        title:
          "Something went wrong while creating a survey please contact to the support",
        position: "top-right",
        isClosable: true,
        variant: "left-accent",
        status: "error",
      });
    }
  };

  const handleClose = () => {
    onClose();
    navigate(`/list-existing?clientId=${clientId}&surveyName=${surveyName}`);
  };

  const handleConfirm = async () => {
    try {
      setIsLiveLoader(true);
      const { data } = await axios({
        method: "put",
        url: `${process.env.REACT_APP_PS_BASE_URL}/buyers/v2/surveys/${response?.ps_id}/status`,
        data: {
          ps_survey_status: 22,
        },
        headers: {
          "access-token": process.env.REACT_APP_PS_ACCESS_TOKEN,
        },
      });
      const surveysCollectionRef = collection(db, surveys?.[surveyName]);
      const surveyRef = doc(surveysCollectionRef, response?.survey_id);
      if (data) {
        await updateDoc(surveyRef, { isLive: true });

        handleClose();
        setIsLiveLoader(false);
      }
    } catch (error) {
      setIsLiveLoader(false);
      toast({
        title:
          error?.response?.data?.ps_api_response_message ||
          "Something went wrong",
        position: "top-right",
        isClosable: true,
        variant: "left-accent",
        status: "error",
      });
    }
  };

  useEffect(() => {
    if (!Object.keys(surveys).includes(surveyName)) {
      navigate("/");
    }
  }, []);
  return (
    <Box
      m={2}
      sx={{
        display: "flex",
        flexDir: "column",
        justifyContent: "center",
        alignItems: "center",
        minH: "90vh",
      }}
    >
      <CustomModal
        text={"Do you want to make this survey live"}
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        loading={isLiveLoader}
        confirmBtnText={"Yes, go live"}
        cancelBtnText={"No, later"}
      />
      <Box
        sx={{
          maxW: 900,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Heading
          as={"h6"}
          size="lg"
          mt={2}
          textAlign={"center"}
          color={"gray.600"}
          mb={4}
        >
          {headings?.[surveyName]}
        </Heading>
        <Menu>
          <MenuButton color="black">
            <Flex alignItems={"center"} cursor="pointer">
              <Text> {language?.name}</Text>
              <Box>
                <Icon as={world} color={"gray.800"} w={71} top={"32px"} />
              </Box>
            </Flex>
          </MenuButton>
          <MenuList minWidth="100px">
            <MenuGroup defaultValue={language.code} type="checkbox">
              {languages.map((val, index) => (
                <MenuItemOption
                  key={index}
                  onClick={() => {
                    setLanguage({
                      name: val.name,
                      code: val.code,
                      survey_Localization: val?.surveyLocalization,
                    });
                    
                    let newFormValues = [...formValues];
                    newFormValues[0].welcomeMsg = val.name === "English" 
                      ? welcomeMsgEng?.[surveyName] || ""
                      : welcomeMsgJap?.[surveyName] || "";
                    setFormValues(newFormValues);
                  }}
                  value={val?.code}
                >
                  {val?.name}
                </MenuItemOption>
              ))}
            </MenuGroup>
          </MenuList>
        </Menu>
        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          onSubmit={HandleSubmit}
        >
          {formValues.map((element, index) => {
            const genderConditionCodes = element?.qualifications?.find(
              (val) => val?.qualification_code === 211
            );
            const ageRenderSets = element?.qualifications?.find(
              (val) => val?.qualification_code === 212
            );

            return (
              <Flex
                flexDir={"column"}
                width={"100%"}
                borderBottom={"1px solid gray"}
                pb={10}
                key={element + index}
              >
                <FormControl mt={8}>
                  <FormLabel>{surveyName === "adTestSurvey" ? "Company Name" : "Product Name"}</FormLabel>
                  <Input
                    type="text"
                    name="name"
                    value={element.name || ""}
                    onChange={(e) => handleChange(index, e)}
                  />
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel>Welcome Message</FormLabel>
                  <Textarea
                    type="text"
                    name="welcomeMsg"
                    value={element.welcomeMsg || ""}
                    onChange={(e) => handleChange(index, e)}
                  />
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel>{surveyName === "adTestSurvey" ? "Company/Ad Description" : "Product Description"}</FormLabel>
                  <Textarea
                    type="text"
                    name="description"
                    value={element.description || ""}
                    onChange={(e) => handleChange(index, e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleEnter(index, e);
                      }
                    }}
                  />
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel>Survey Category</FormLabel>
                  <CustomDropdown
                    placeholder="Select Survey Category"
                    name="surveyCategory"
                    value={element.surveyCategory}
                    options={surveyData}
                    onChange={(e) =>
                      handleChange(index, {
                        ...e,
                        target: {
                          ...e.target,
                          name: "surveyCategory",
                          value: Number(e.target.value),
                        },
                      })
                    }
                  />
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel>Gender</FormLabel>
                  <Menu closeOnSelect={false} width="100%" position="relative">
                    <MenuButton
                      as={Button}
                      variant="outline"
                      color="black"
                      textAlign="start"
                      width="100%"
                    >
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        cursor="pointer"
                      >
                        <Text>
                          {!genderConditionCodes?.condition_codes?.length
                            ? "Select Gender"
                            : genderConditionCodes?.condition_codes?.length ===
                              genderData?.length
                            ? "All Selected"
                            : `${genderData
                                .filter((item) =>
                                  genderConditionCodes?.condition_codes.includes(
                                    item.value
                                  )
                                )
                                .map((item) => item.label)
                                .join(", ")}`}
                        </Text>
                        <Box>
                          <FaChevronDown fontSize="10px" />
                        </Box>
                      </Flex>
                    </MenuButton>
                    <MenuList position="absolute" width="100%">
                      <VStack align="start" p={2} width="100%">
                        <Checkbox
                          isChecked={
                            genderConditionCodes?.condition_codes?.length ===
                            genderData?.length
                          }
                          onChange={() =>
                            handleSelectGender(index, "Select All")
                          }
                        >
                          {genderConditionCodes?.condition_codes?.length ===
                          genderData?.length
                            ? "Deselect All"
                            : "Select All"}
                        </Checkbox>
                        {genderData.map((item, i) => (
                          <Checkbox
                            key={i}
                            isChecked={genderConditionCodes.condition_codes.includes(
                              item.value
                            )}
                            onChange={() =>
                              handleSelectGender(index, item.value)
                            }
                          >
                            {item.label}
                          </Checkbox>
                        ))}
                      </VStack>
                    </MenuList>
                  </Menu>
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel>Age</FormLabel>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    cursor="pointer"
                    className="age-wrapper"
                  >
                    <Input
                      placeholder="33"
                      type="number"
                      value={ageRenderSets.range_sets[0]?.from}
                      onChange={(e) =>
                        handleSelectRange(index, Number(e.target.value), "from")
                      }
                    />
                    <Box sx={{ backgroundColor: "#e9ecef", padding: "8px" }}>
                      to
                    </Box>
                    <Input
                      placeholder="99"
                      type="number"
                      value={ageRenderSets.range_sets[0]?.to}
                      onChange={(e) =>
                        handleSelectRange(index, Number(e.target.value), "to")
                      }
                    />
                  </Flex>
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel>{surveyName === "adTestSurvey" ? "Ad Image" : "Product Image"}</FormLabel>
                  <Input
                    type="file"
                    name="image"
                    // value={element.image || ""}
                    onChange={(e) => handleChange(index, e)}
                  />
                </FormControl>

                {index ? (
                  <Button
                    type="button"
                    className="button remove"
                    mt={2}
                    onClick={() => removeFormFields(index)}
                  >
                    Remove
                  </Button>
                ) : null}
              </Flex>
            );
          })}
          <Flex mt={3}>
            <Button
              colorScheme="teal"
              size="lg"
              mr={5}
              onClick={() =>
                navigate(`/?clientId=${clientId}&surveyName=${surveyName}`)
              }
            >
              Back
            </Button>

            <Button
              colorScheme="teal"
              size="lg"
              type="submit"
              isLoading={isSaving}
              isDisabled={isSaving}
            >
              Save
            </Button>
          </Flex>
        </form>
      </Box>
    </Box>
  );
};

export default CreateNew;

const world = createIcon({
  displayName: "Arrow",
  viewBox: "0 0 72 20",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.252 8A8.014 8.014 0 0 0 2 10c0 .69.088 1.36.252 2H5.1a19.83 19.83 0 0 1 0-4H2.252zm.818-2h2.346c.266-1.217.65-2.307 1.121-3.214A8.035 8.035 0 0 0 3.07 6zm14.678 2H14.9a19.83 19.83 0 0 1 0 4h2.848c.164-.64.252-1.31.252-2s-.088-1.36-.252-2zm-.818-2a8.035 8.035 0 0 0-3.467-3.214c.472.907.855 1.997 1.121 3.214h2.346zM7.112 8A17.763 17.763 0 0 0 7 10c0 .685.038 1.355.112 2h5.776a17.763 17.763 0 0 0 0-4H7.112zm.358-2h5.06a10.758 10.758 0 0 0-.783-2.177C11.119 2.568 10.447 2 10 2c-.448 0-1.119.568-1.747 1.823-.315.632-.58 1.367-.783 2.177zm-4.4 8a8.035 8.035 0 0 0 3.467 3.214c-.472-.907-.855-1.997-1.121-3.214H3.07zm13.86 0h-2.346c-.266 1.217-.65 2.307-1.121 3.214A8.035 8.035 0 0 0 16.93 14zm-9.46 0c.203.81.468 1.545.783 2.177C8.881 17.432 9.553 18 10 18c.448 0 1.119-.568 1.747-1.823.315-.632.58-1.367.783-2.177H7.47zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"
      fill="currentColor"
    />
  ),
});
