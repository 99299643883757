import React from "react";
import { 
  Box, 
  Flex, 
  Spacer, 
  Button, 
  Heading, 
  Image, 
  Menu, 
  MenuButton,
  MenuList,
  MenuItem,
  Avatar
} from "@chakra-ui/react";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();
  const currentUser = auth.currentUser;

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate("/signin");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Box bg="teal.500" px={4} py={2} color="white">
      <Flex align="center">
        <Image src="/logo.png" alt="Logo" h="40px" />
        <Heading size="md" ml={2}>Survey Platform</Heading>
        <Spacer />
        
        {currentUser && (
          <Menu>
            <MenuButton as={Button} variant="ghost" rounded="full">
              <Avatar 
                size="sm" 
                name={currentUser.displayName} 
                src={currentUser.photoURL} 
              />
            </MenuButton>
            <MenuList color="black">
              <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
    </Box>
  );
};

export default NavBar; 