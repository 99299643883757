import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const CustomModal = ({
  isOpen,
  onClose = () => {},
  onConfirm = () => {},
  text = "",
  cancelBtnText = "",
  confirmBtnText = "",
  loading,
}) => {
  return (
    <Modal isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        style={{
          padding: "32px",
        }}
      >
        <ModalBody
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
            fontSize: "22px",
            fontWeight: 500,
            padding: 0,
            textAlign: "center",
            marginBottom: "12px",
          }}
        >
          {text}
        </ModalBody>
        <ModalFooter
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
            flexWrap: "wrap",
          }}
        >
          <Button size="md" onClick={onClose}>
            {cancelBtnText}
          </Button>
          <Button
            colorScheme="teal" 
            size="md"
            onClick={onConfirm}
            isLoading={loading}
            isDisabled={loading}
          >
            {confirmBtnText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
