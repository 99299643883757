import React, { useEffect, useState } from "react";
import { Box, Button, Center, Flex, Heading, Image, Text, useToast } from "@chakra-ui/react";
import { auth } from "../firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  // Check if user is already signed in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        // Navigate to home page with clientId
        navigate(`/?clientId=${user.uid}`);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      // Simply navigate to home page after successful sign-in
      // User creation will be handled in the Home component
      navigate(`/?clientId=${user.uid}`);
      
      toast({
        title: "Sign-in successful",
        description: "Welcome to the survey platform",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      
    } catch (error) {
      console.error("Error signing in with Google:", error);
      toast({
        title: "Sign-in failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box height="100vh" width="100%" bg="gray.50">
      <Text
        fontSize="3xl"
        fontWeight="bold"
        color="teal.800"
        textAlign="center"
        p={10}
      >
        The People's Survey
      </Text>
      <Center height="60%">
        <Flex 
          direction="column" 
          align="center" 
          p={8} 
          bg="white" 
          boxShadow="md" 
          borderRadius="lg"
          maxW="400px"
          w="100%"
        >
          <Image 
            src="/logo.png" 
            alt="Logo" 
            mb={3} 
            maxH="80px"
          />
          <Heading size="lg" mb={2}>Welcome</Heading>
          <Text mb={6} color="gray.600" textAlign="center">
            To create and manage your surveys
          </Text>
          <Button
            leftIcon={
              <Image 
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" 
                alt="Google" 
                boxSize="18px"
              />
            }
            colorScheme="teal"
            variant="outline"
            size="lg"
            isLoading={isLoading}
            onClick={handleGoogleSignIn}
            w="75%"
          >
            Sign-in with Google
          </Button>
        </Flex>
      </Center>
    </Box>
  );
};

export default SignIn; 