import { Box, Button, Flex, Heading, useToast } from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RenderQuestion } from "./RenderQuestion";
import { useRecoilState } from "recoil";
import { ProductState } from "../state/atom/ProductState";
import { getSurvey, updateSurveyQuestions } from "../utils/Helper";
import Loader from "./Loader";

const UpdateExisting = ({ clientId, productId }) => {
  const [searchParams] = useSearchParams();
  const surveyName = searchParams.get("surveyName");
  const navigate = useNavigate();
  const [product, setProduct] = useRecoilState(ProductState);
  const [surveyData, setSurveyData] = useState({
    screeningQuestions: [],
    questions: [],
    closingMessages: [],
    language: null
  });
  const [loading, setLoading] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const toast = useToast();


  const processProductData = useCallback((data) => {
    if (!data) {
      console.error("No data available to process");
      setLoading(false);
      toast({
        title: "Error loading survey data",
        description: "Survey data could not be loaded. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const processedData = {
        language: data?.language,
        screeningQuestions: data?.screenerQuestions ? 
          Object.values(data.screenerQuestions)
            .sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0)) : [],
        questions: data?.questions ? 
          Object.values(data.questions)
            .sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0)) : [],
        closingMessages: data?.closingMessages ? 
          [...data.closingMessages]
            .sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0)) : []
      };

      setSurveyData(processedData);
      setProduct(data);
      setLoading(false);
    } catch (error) {
      console.error("Error processing survey data:", error);
      setLoading(false);
      toast({
        title: "Error",
        description: "Failed to process survey data",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [setProduct, toast]);

  useEffect(() => {
    let mounted = true;

    const fetchSurveyData = async () => {
      if (!clientId || !productId || !surveyName) {
        setLoading(false);
        return;
      }

      try {
        const res = await getSurvey(clientId, productId, surveyName);
        if (!mounted) return;

        if (res?.success) {
          processProductData(res.data);
        } else {
          throw new Error(res?.message || "Failed to fetch survey");
        }
      } catch (error) {
        if (!mounted) return;
        console.error("Error fetching survey:", error);
        toast({
          title: "Error",
          description: error.message || "Failed to load survey data",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
      }
    };

    fetchSurveyData();

    return () => {
      mounted = false;
    };
  }, [clientId, productId, surveyName, processProductData, toast]);

  const updateScreeningQuestion = (index, newValue) => {
    setSurveyData(prev => ({
      ...prev,
      screeningQuestions: prev.screeningQuestions.map((q, i) => 
        i === index ? { ...q, question: newValue } : q
      )
    }));
  };

  const updateScreeningOption = (index, optionIndex, option) => {
    setSurveyData(prev => ({
      ...prev,
      screeningQuestions: prev.screeningQuestions.map((q, i) => 
        i === index ? { ...q, options: q.options.map((o, j) => j === optionIndex ? option : o) } : q
      )
    }));
  };

  const updateMessages = (index, newValue) => {
    setSurveyData(prev => ({
      ...prev,
      closingMessages: prev.closingMessages.map((q, i) => 
        i === index ? { ...q, value: newValue } : q
      )
    }));
  };

  const updateQuestion = (index, newValue) => {
    setSurveyData(prev => ({
      ...prev,
      questions: prev.questions.map((q, i) => 
        i === index ? { ...q, question: newValue } : q
      )
    }));
  };

  const updateOption = (index, optionIndex, option) => {
    setSurveyData(prev => ({
      ...prev,
      questions: prev.questions.map((q, i) => 
        i === index ? { ...q, options: q.options.map((o, j) => j === optionIndex ? option : o) } : q
      )
    }));
  };

  const updateNestedQuestion = (index, optionIndex, option) => {
    setSurveyData(prev => ({
      ...prev,
      questions: prev.questions.map((q, i) => 
        i === index ? { ...q, nestedQuestion: q.nestedQuestion.map((o, j) => j === optionIndex ? option : o) } : q
      )
    }));
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setIsBtnLoading(true);

    try {
      const cleanData = {
        ...product,
        language: surveyData.language || null,
        screenerQuestions: surveyData.screeningQuestions?.length > 0 ? 
          surveyData.screeningQuestions.reduce((acc, val, idx) => {
            if (val) acc[idx] = val;
            return acc;
          }, {}) : null,
        questions: surveyData.questions?.length > 0 ? 
          surveyData.questions.reduce((acc, val, idx) => {
            if (val) acc[idx] = val;
            return acc;
          }, {}) : null,
        closingMessages: surveyData.closingMessages?.length > 0 ? 
          surveyData.closingMessages : null,
      };


      Object.keys(cleanData).forEach(key => {
        if (cleanData[key] === undefined) {
          delete cleanData[key];
        }
      });

      await updateSurveyQuestions(clientId, productId, surveyName, cleanData);
      
      toast({
        title: "Success!",
        description: "Survey Updated Successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      setTimeout(() => {
        navigate(`/list-existing?clientId=${clientId}&surveyName=${surveyName}`);
      }, 2000);
    } catch (error) {
      console.error("Error updating survey:", error);
      toast({
        title: "Error!",
        description: error.message || "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsBtnLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box m={2} sx={{
      display: "flex",
      flexDir: "column",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <Box m={2} sx={{
        width: 600,
        maxW: 1200,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}>
        <Heading as="h6" size="lg" mt={2} textAlign="center">
          {surveyName==="adTestSurvey" ? "Company ": "Product "} name: {product?.name || "Unnamed Survey"}
        </Heading>
        
        <form style={{ width: "100%" }}>
          {surveyData.screeningQuestions.length > 0 && (
            <div>
              <Heading as="h2" size="lg" color="gray.600" mt={2} mb={5} textAlign="center">
                Screening Questions
              </Heading>
              {surveyData.screeningQuestions.map((question, index) => (
                <RenderQuestion
                  key={question.questionId || index}
                  question={question}
                  index={index + 1}
                  onChange={(value) => updateScreeningQuestion(index, value)}
                  onChangeOption={(optionIndex, newValue) =>
                    updateScreeningOption(index, optionIndex, newValue)
                  }
                  onChangeNestedQuestion={(optionIndex, newValue) =>
                    updateNestedQuestion(index, optionIndex, newValue)
                  }
                />
              ))}
            </div>
          )}

          {surveyData.questions.length > 0 && (
            <div>
              <Heading as="h2" size="lg" color="gray.600" mt={3} textAlign="center">
                Questions
              </Heading>
              {surveyData.questions.map((question, index) => (
                <RenderQuestion
                  key={question.questionId || index}
                  question={question}
                  index={index + 1}
                  onChange={(value) => updateQuestion(index, value)}
                  onChangeOption={(optionIndex, newValue) =>
                    updateOption(index, optionIndex, newValue)
                  }
                  onChangeNestedQuestion={(optionIndex, newValue) =>
                    updateNestedQuestion(index, optionIndex, newValue)
                  }
                />
              ))}
            </div>
          )}

          {surveyData.closingMessages.length > 0 && (
            <div>   
              <Heading as="h2" size="lg" color="gray.600" mt={3} textAlign="center">
                Closing Messages
              </Heading>
              {surveyData.closingMessages.map((question, index) => (
                <RenderQuestion
                  key={question.questionId || index}
                  question={question}
                  index={index + 1}
                  onChange={(value) => updateMessages(index, value)}
                />
              ))}
            </div>
          )}

          <Flex mt={6} mb={5} justifyContent="center">
            <Button
              colorScheme="teal"
              size="lg"
              mr={5}
              onClick={() => navigate(`/list-existing?clientId=${clientId}&surveyName=${surveyName}`)}
            >
              Back
            </Button>
            <Button
              colorScheme="teal"
              size="lg"
              onClick={HandleSubmit}
              isLoading={isBtnLoading}
            >
              Save
            </Button>
          </Flex>
        </form>
      </Box>
    </Box>
  );
};

export default UpdateExisting;
