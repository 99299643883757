import {
  Checkbox,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";

const CustomRadio = ({ options, type, onChange, isDisabled }) => {
  const [currentOptions] = useState(options);

  return (
    <RadioGroup defaultValue="1" mb={2} mt={2}>
      <Stack spacing={4} direction="column" mb={2}>
        {currentOptions?.map((radio, index) => (
          <Flex key={radio}>
            {type === "radio" ? (
              <Radio key={radio} mr={4} ml={2} value={radio} />
            ) : type === "checkbox" ? (
              <Checkbox size="md" color="green">
                {radio}
              </Checkbox>
            ) : null}
            <Input
              defaultValue={radio}
              key={radio + index}
              disabled={isDisabled}
              onChange={(e) => onChange(index, e.target.value)}
            />
          </Flex>
        ))}
        {type === "text" && (
          <Flex>
            <Flex justifyContent={"center"} alignItems={"center"} mr={1}>
              Ans:
            </Flex>
            <Textarea />
          </Flex>
        )}
      </Stack>
    </RadioGroup>
  );
};

export default CustomRadio;
